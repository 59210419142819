import {
  PeopleOutlined,
  DescriptionOutlined,
  SettingsOutlined,
  ViewListSharp,
  Dashboard,
  Event,
  FilterHdr,
  Favorite,
  Storefront,
  Receipt,
  School,
} from '@material-ui/icons'
import home from 'pages/Home/routes'
import admins from 'pages/Admins/routes'
import adminGroups from 'pages/AdminGroups/routes'
import loginEntries from 'pages/LoginEntries/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import settings from 'pages/Settings/routes'
import profile from 'pages/Profile/routes'
import pages from 'pages/Pages/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import packageGalleries from 'pages/PackageGalleries/routes'
import packageFiles from 'pages/PackageFiles/routes'
import news from 'pages/News/routes'
import newsCategory from 'pages/NewsCategory/routes'
import contest from 'pages/Contest/routes'
import config from 'pages/Config/routes'
import homeBanner from 'pages/HomeBanner/routes'
import customer from 'pages/Customer/routes'
//import customerDeleted from 'pages/CustomerDeleted/routes'
import footer from 'pages/Footer/routes'
import event from 'pages/Event/routes'
import eventCategory from 'pages/EventCategory/routes'
import trail from 'pages/Trail/routes'
import trailCategory from 'pages/TrailCategory/routes'
import trailSubcategory from 'pages/TrailSubcategory/routes'
import trailReview from 'pages/TrailReview/routes'
import park from 'pages/Park/routes'
import volunteering from 'pages/Volunteering/routes'
import volunteeringCategory from 'pages/VolunteeringCategory/routes'
import volunteeringAccommodation from 'pages/VolunteeringAccommodation/routes'
import volunteeringLocation from 'pages/VolunteeringLocation/routes'
import volunteeringConfig from 'pages/VolunteeringConfig/routes'
import volunteeringEvent from 'pages/VolunteeringEvent/routes'
import volunteerTimesheet from 'pages/VolunteerTimesheet/routes'
import volunteerSubmission from 'pages/VolunteeringSubmission/routes'
import volunteerUser from 'pages/VolunteerUser/routes'
import shopProduct from 'pages/ShopProduct/routes'
import shopProductReview from 'pages/ShopProductReview/routes'
import shopVariant from 'pages/ShopVariant/routes'
import shopCategory from 'pages/ShopCategory/routes'
import shopDiscountCode from 'pages/ShopDiscountCode/routes'
import shopPaymentMethod from 'pages/ShopPaymentMethod/routes'
import shopPromotion from 'pages/ShopPromotion/routes'
import shopShippingArea from 'pages/ShopShippingArea/routes'
import shopShippingCountry from 'pages/ShopShippingCountry/routes'
import shopShippingType from 'pages/ShopShippingType/routes'
import shopOrder from 'pages/ShopOrder/routes'
import shopFooter from 'pages/ShopFooter/routes'
import shopSubscription from 'pages/ShopSubscription/routes'
import shopElektronicznyNadawca from 'pages/ShopElektronicznyNadawca/routes'
import shopConfig from 'pages/ShopConfig/routes'
import shopReport from 'pages/ShopReport/routes'
import shopReturn from 'pages/ShopReturn/routes'
import bipPage from 'pages/BipPage/routes'
import bipEntity from 'pages/BipEntity/routes'
import bipMenuCategory from 'pages/BipMenuCategory/routes'
import crewItem from 'pages/CrewItem/routes'
import socialMediaItem from 'pages/SocialMediaItem/routes'
import socialMediaItemLiten from 'pages/SocialMediaItemListen/routes'
import formset from 'pages/Formset/routes'
//import tourismNotice from 'pages/TourismNotice/routes'
import tourismNoticeArticle from 'pages/TourismNoticeArticle/routes'
import mainMenu from 'pages/MainMenu/routes'
import homeBlocks from 'pages/HomeBlocks/routes'
import accomm from 'pages/Accommodation/routes'
import accommCat from 'pages/AccommodationCategory/routes'
import accommUser from 'pages/AccommodationUser/routes'
import accommConf from 'pages/AccommodationConfig/routes'
import accommPdf from 'pages/AccommodationPdf/routes'
import accomMessage from 'pages/AccommodationMessage/routes'
import accommNot from 'pages/AccommodationNotice/routes'
import tourney from 'pages/Tourney/routes'
import tourneyUser from 'pages/TourneyUser/routes'
import tourneyConfig from 'pages/TourneyConfig/routes'
import matter from 'pages/Matter/routes'
import matterFormConfigGroup from 'pages/MatterFormConfigGroup/routes'
import matterFormRequest from 'pages/MatterFormRequest/routes'
import matterFormPermission from 'pages/MatterFormPermission/routes'
import guides from 'pages/Guides/routes'
import guideFees from 'pages/GuideFees/routes'
import guidePermissions from 'pages/GuidePermissions/routes'
import guideLicences from 'pages/GuideLicences/routes'
import seaEyeFiacres from 'pages/SeaEyeFiacres/routes'
import seaEyeHorses from 'pages/SeaEyeHorses/routes'
import seaEyeInspections from 'pages/SeaEyeInspections/routes'
import seaEyeHistoryEntries from 'pages/SeaEyeHistoryEntries/routes'
import churchiscoFiacres from 'pages/ChurchiscoFiacres/routes'
import churchiscoInspections from 'pages/ChurchiscoInspections/routes'
import churchiscoHistoryEntries from 'pages/ChurchiscoHistoryEntries/routes'
import lesson from 'pages/Lesson/routes'
import lessonCommune from 'pages/LessonCommune/routes'
import lessonDistrict from 'pages/LessonDistrict/routes'
import lessonSchedule from 'pages/LessonSchedule/routes'
import lessonSubmission from 'pages/LessonSubmission/routes'
import lessonMySubmission from 'pages/LessonMySubmission/routes'
import lessonHost from 'pages/LessonHost/routes'
import lessonConfig from 'pages/LessonConfig/routes'
import weatherLocation from 'pages/WeatherLocation/routes'
import serachedWord from 'pages/SearchedWord/routes'
import seoRedirect from 'pages/SeoRedirect/routes'
import matterCalendar from 'pages/MatterCalendar/routes'
import changelog from 'pages/Changelog/routes'
import managerMedia from 'pages/ManagerMedia/routes'
import deletedUser from 'pages/DeletedUser/routes'

const menu = () => [
  home().index,
  pages().index,
  homeBlocks().edit,
  {
    title: 'T_MENU_PACKAGES',
    icon: ViewListSharp,
    items: [
      packageGalleries().index,
      packageFiles().index,
      //packageFaqs().index,
      //packageLogotypes().index,
      formset().index,
    ],
  },
  managerMedia().index,
  {
    title: 'T_MENU_PORTAL',
    icon: Dashboard,
    items: [
      homeBanner().index,
      park().index,
      //tourismNotice().index,
      tourismNoticeArticle().edit,
    ],
  },
  {
    title: 'T_MENU_EVENTS',
    icon: Event,
    items: [event().index, eventCategory().index],
  },
  {
    title: 'T_MENU_TRAILS',
    icon: FilterHdr,
    items: [
      trail().index,
      trailCategory().index,
      trailSubcategory().index,
      trailReview().index,
    ],
  },
  deletedUser().index,
  {
    title: 'T_MENU_VOLUNTEERING',
    icon: Favorite,
    items: [
      volunteering().index,
      volunteeringCategory().index,
      volunteerUser().index,
      volunteerSubmission().index,
      volunteeringAccommodation().index,
      volunteeringLocation().index,
      volunteerTimesheet().index,
      volunteeringEvent().index,
      volunteeringConfig().edit,
    ],
  },
  crewItem().index,
  {
    title: 'T_MENU_SHOP',
    icon: Storefront,
    items: [
      shopProduct().index,
      customer().index,
      //customerDeleted().index,
      shopOrder().index,
      shopVariant().index,
      shopCategory().index,
      shopDiscountCode().index,
      shopPaymentMethod().index,
      shopPromotion().index,
      shopShippingArea().index,
      shopShippingCountry().index,
      shopShippingType().index,
      shopFooter().index,
      shopProductReview().index,
      shopSubscription().index,
      shopReport().index,
      shopReturn().index,
      shopElektronicznyNadawca().buforIndex,
      shopConfig().edit,
    ],
  },
  {
    title: 'T_MENU_BIP',
    icon: Receipt,
    items: [bipPage().index, bipEntity().index, bipMenuCategory().index],
  },
  {
    title: 'T_MENU_NEWS',
    icon: news().index.icon,
    items: [news().index, newsCategory().index],
  },
  contest().index,
  {
    title: 'T_MODULE_MATTER',
    icon: matter().index.icon,
    items: [
      matter().index,
      matterFormConfigGroup().index,
      matterFormRequest().awaiting,
      matterFormRequest().index,
      matterFormPermission().index,
      matterCalendar().index,
    ],
  },
  {
    title: 'T_MODULE_ACCOMM',
    icon: accomm().index.icon,
    items: [
      accomm().index,
      accommCat().index,
      accommUser().index,
      accomMessage().index,
      accommPdf().index,
      accommNot().index,
      accommConf().edit,
    ],
  },
  {
    title: 'Turnieje',
    icon: tourney().index.icon,
    items: [tourney().index, tourneyUser().index, tourneyConfig().edit],
  },
  {
    title: 'Administrators',
    icon: PeopleOutlined,
    items: [admins().index, adminGroups().index],
  },
  changelog().index,
  {
    title: 'T_MENU_REGISTRIES',
    icon: DescriptionOutlined,
    items: [registryEntries().index, loginEntries().index],
  },
  {
    title: 'T_MODULE_SETTINGS',
    icon: SettingsOutlined,
    items: [
      config().edit,
      settings().index,
      profile().index,
      mainMenu().index,
      footer().index,
      socialMediaItem().index,
      socialMediaItemLiten().index,
      weatherLocation().index,
      serachedWord().index,
      seoRedirect().index,
    ],
  },
  {
    title: 'T_MODULE_GUIDE',
    icon: PeopleOutlined,
    items: [
      guides().index,
      guidePermissions().index,
      guideLicences().index,
      guideFees().index,
    ],
  },
  {
    title: 'T_MODULE_SEA_EYE_FIACRE',
    icon: PeopleOutlined,
    items: [
      seaEyeFiacres().index,
      seaEyeHorses().index,
      seaEyeInspections().index,
      seaEyeHistoryEntries().index,
    ],
  },
  {
    title: 'T_MODULE_CHURCHISCO_FIACRE',
    icon: PeopleOutlined,
    items: [
      churchiscoFiacres().index,
      churchiscoInspections().index,
      churchiscoHistoryEntries().index,
    ],
  },
  {
    title: 'T_MENU_LESSON',
    icon: School,
    items: [
      lessonMySubmission().index,
      lessonSubmission().index,
      lessonSchedule().index,
      lesson().index,
      lessonHost().index,
      lessonDistrict().index,
      lessonCommune().index,
      lessonConfig().edit,
    ],
  },
  translatorEntries().index,
]

export default menu
