import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { TableCell } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { filters as filterComponents } from './filters'
import { FilterError } from './_helpers'
import { TableReset } from './TableReset'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    columnGap: 10,
  },

  disabled: {
    cursor: 'default',
    color: theme.palette.disabled,
    '&:hover': {
      color: theme.palette.disabled,
    },
  },

  filterWrapper: {
    marginBottom: 0,
  },

  additionalFilter: {
    '& .filter-input': {
      marginTop: 0,
    },
  },
}))

export const FilterRow = ({
  filters,
  disabled = false,
  handleFilters,
  preparedColumns,
  defaultClasses,
  autoWidth,
  columnsAmount,
  resetable,
  state,
  handleReset,
}) => {
  const classes = useStyles()

  const [filterState, setFilterState] = useState(filters)

  const renderFilter = (
    filterObject,
    additionalFilter = false,
    column = null
  ) => {
    if (
      typeof filterObject.type === 'string' &&
      !filterComponents[filterObject.type] &&
      !column?.Filter &&
      filterObject?.Filter
    ) {
      throw new FilterError(filterObject.type)
    }

    let FilterComponent = null

    if (filterObject?.Filter) {
      FilterComponent = filterObject.Filter
    } else if (column?.Filter) {
      FilterComponent = column.Filter
    } else {
      FilterComponent =
        typeof filterObject.type === 'string'
          ? filterComponents[filterObject.type]
          : filterObject.type
    }

    return FilterComponent ? (
      <div
        className={
          additionalFilter
            ? clsx(
                classes.filterWrapper,
                classes.additionalFilter,
                FilterComponent
              )
            : classes.filterWrapper
        }
        key={`filter-${filterObject.name}`}
      >
        <FilterComponent
          name={filterObject.name}
          title={filterObject.title}
          value={filterObject.value}
          filterState={filterState}
          disabled={false}
          handleEnter={handleSearch}
          setFilter={setFilter}
          handleFilter={handleFilter}
          endpoint={filterObject?.endpoint}
          titleAccessor={filterObject?.titleAccessor}
          rowType={true}
          choices={column?.choices}
          values={column?.values}
          titles={filterObject?.titles}
        />
      </div>
    ) : (
      <span style={{ color: 'red' }}>{'invalid filter configuration'}</span>
    )
  }

  const debounceTimeout = useRef(null)

  const setFilter = (name, value) => {
    setFilterState(prevState => {
      const newState = {
        ...prevState,
        [name]: {
          ...prevState[name],
          value,
        },
      }

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }

      debounceTimeout.current = setTimeout(() => {
        handleFilters(newState)
      }, 1000)

      return newState
    })
  }

  const handleFilter = useCallback(
    (name, value) => {
      handleFilters({
        [name]: {
          value,
        },
      })
    },
    [handleFilters]
  )

  const handleSearch = () => {
    if (disabled) {
      return
    }

    handleFilters(filterState)
  }

  useEffect(() => {
    setFilterState(filters)
  }, [filters, setFilterState])

  return preparedColumns.map((column, i) => {
    let cellFilters = []

    if (column?.filterable !== false) {
      let filterObject =
        filterState[column?.filterName] ||
        filterState[column?.accessor] ||
        filterState[column?.columnId]

      if (Array.isArray(column?.filtersList)) {
        column.filtersList.forEach(filterName => {
          if (filterState[filterName]) {
            cellFilters.push(filterState[filterName])
          }
        })
      } else if (filterObject) {
        cellFilters.push(filterObject)
      }

      const filterString = column?.filterName?.match(/.*\[(.*?)\]/)
      if (filterString) {
        const filterName = filterString[1]
        if (filterState[filterName]) {
          cellFilters.push(filterState[filterName])
        }
      }
    }

    return column?.columnId === 'operations' ? (
      <TableCell
        key={`header-filter-${i}`}
        className={
          cellFilters?.length === 0
            ? defaultClasses.tHeadOperationsCell
            : defaultClasses.tHeadFilterCell
        }
        style={
          autoWidth
            ? { width: 100 / columnsAmount + '%' }
            : column.width && { width: column.width }
        }
      >
        {cellFilters.map((filterObject, index) =>
          renderFilter(filterObject, index !== 0, column)
        )}
        {resetable && (
          <TableReset
            disabled={state.isInit || state.isFetching}
            handleReset={handleReset}
            rowType={true}
          />
        )}
      </TableCell>
    ) : (
      <TableCell
        key={`header-${i}`}
        className={defaultClasses.tHeadFilterCell}
        style={
          autoWidth
            ? { width: 100 / columnsAmount + '%' }
            : column.width && { width: column.width }
        }
      >
        {resetable && column?.columnId === 'operations' && (
          <TableReset
            disabled={state.isInit || state.isFetching}
            handleReset={handleReset}
            rowType={true}
          />
        )}
        {cellFilters.map((filterObject, index) =>
          renderFilter(filterObject, index !== 0, column)
        )}
      </TableCell>
    )
  })
}

FilterRow.propTypes = {
  filters: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
        .isRequired,
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ).isRequired,
  disabled: PropTypes.bool,
  handleFilters: PropTypes.func.isRequired,
}
