import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { DateCell } from 'components/table/cells/DateCell'
import schema from '_schema/guideFee'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'parent.fullName',
        header: translate('Full name'),
        accessor: 'parent.fullName',
        width: '35%',
      },
      {
        columnId: 'parent.type',
        header: translate('Type'),
        accessor: 'parent.type',
        width: '20%',
      },
      {
        columnId: 'amountReal',
        header: translate('Amount'),
        accessor: 'amountReal',
        width: '15%',
      },
      {
        columnId: 'paidAt',
        header: translate('Paid at'),
        accessor: 'paidAt',
        Cell: DateCell,
        width: '15%',
      },
      {
        columnId: 'operations',
        ...operations('title', true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_GUIDE_FEE_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('T_MODULE_GUIDE_FEE')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ paidAt: 'desc' }}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
