import React, { useEffect, useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { translate } from '_helpers/translate'
import schema from '_schema/shopOrder'
import customerSchema from '_schema/customer'
import routes from './routes'
import { orderOperations } from './table/columns/orderOperations'
import { DatetimeCell } from '../../components/table/cells/DatetimeCell'
import { StringFilter } from '../../components/table/filters/StringFilter'
import { elektronicznyNadawcaXls } from './table/_mass/'
import { PriceCell } from '../../components/table/cells/PriceCell'
import { ResourceFilter } from '../../components/table/filters/ResourceFilter'
import { exportselected } from './table/_mass/exportselected'
import { statusselected } from './table/_mass/statusselected'
import { notification } from '_helpers/notification'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { StatusCell } from './table/cells/StatusCell'

export const Collection = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  /*
  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])
   */
  let customerIri = null

  const [reloadFilters, setReloadFilters] = useState(!!match.params.id)

  let defaultFilters = {}
  let endpoint = schema.endpoint

  if (match.params.id) {
    defaultFilters = {
      'iri[customer]': `${customerSchema.endpoint}/${match.params.id}`,
    }
    customerIri = `${customerSchema.endpoint}/${match.params.id}`
    endpoint = schema.endpointCustomer
  } else {
    defaultFilters = {
      'iri[customer]': null,
    }
  }

  const columns = useMemo(
    () => [
      {
        columnId: 'orderId',
        header: translate('Numer zamówienia'),
        accessor: `orderId`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'fullName',
        filtersList: ['firstName', 'lastName'],
        header: translate('Zamawiający'),
        accessor: `fullName`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'email',
        header: translate('Email'),
        accessor: `email`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'shippingTitle',
        header: translate('Metoda wysyłki'),
        accessor: `shippingTitle`,
        filterName: 'shippingTitle',
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'totalPrice',
        filtersList: ['totalPrice[gte]', 'totalPrice[lte]'],
        header: translate('Łączna kwota'),
        accessor: `totalPrice`,
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: PriceCell,
      },
      {
        columnId: 'currentStatus',
        filtersList: ['iri[status]'],
        header: translate('Status'),
        accessor: `currentStatus`,
        translateAccessor: true,
        sorterName: 'status',
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: StatusCell,
      },
      {
        columnId: 'legacyStatus',
        filtersList: ['iri[legacyStatus]'],
        header: translate('Status(IMPORT)'),
        accessor: `legacyStatus.trans`,
        translateAccessor: true,
        sorterName: 'legacyStatus',
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'createdAt',
        header: translate('Data utworzenia'),
        accessor: `createdAt`,
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: DatetimeCell,
      },
      {
        columnId: 'operations',
        ...orderOperations(`fullName`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [statuses, setChoices] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    const endpoint2 = `/api/shop_order_statuses?pagination=false`

    fetchDataHandleAuthError(
      endpoint2,
      'GET',
      { signal },
      response => {
        const data = response['hydra:member']
        if (data.length) {
          const choices = []
          data.map(i => choices.push(statusselected.status(i['@id'], i.trans)))
          setChoices(choices)
        }
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [setChoices])

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_SHOP_ORDER_NEW_BUTTON')}
        buttonPath={routes().new.path}
        hideButton={false}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('T_MODULE_SHOP_ORDER')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${endpoint}.get`}
        endpoint={endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        defaultFilters={defaultFilters}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        numerable={false}
        isManualOrderSorterVisible={true}
        selectable={[
          elektronicznyNadawcaXls.generateXls('generateXls', 'orderUuid'),
          exportselected.go(),
        ]}
        selectable2={statuses}
        parameters={[
          {
            name: 'orderId',
            in: 'query',
            type: 'string',
          },
          {
            name: 'firstName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'lastName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'createdAt',
            in: 'query',
            type: 'string',
          },
          {
            name: 'email',
            in: 'query',
            type: 'string',
          },
          {
            name: 'shippingType.translations.title',
            in: 'query',
            type: 'string',
          },
          {
            name: 'iri[customer]',
            in: 'query',
            type: 'string',
            value: customerIri,
          },
          {
            name: 'iri[status]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'iri[legacyStatus]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'totalPrice[gte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'totalPrice[lte]',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[email]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[status]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[legacyStatus]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[totalPrice]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[orderId]',
            in: 'query',
            type: 'string',
          },
        ]}
        customFilters={[
          {
            name: 'firstName',
            title: 'Imię',
            Filter: StringFilter,
          },
          {
            name: 'lastName',
            title: 'Nazwisko',
            Filter: StringFilter,
          },
          {
            name: 'totalPrice[gte]',
            title: 'Cena od',
            Filter: StringFilter,
          },
          {
            name: 'totalPrice[lte]',
            title: 'Cena do',
            Filter: StringFilter,
          },
          {
            name: 'iri[status]',
            title: 'Status',
            Filter: ResourceFilter,
            endpoint: '/api/shop_order_statuses?pagination=false',
            titleAccessor: 'trans',
          },
          {
            name: 'iri[legacyStatus]',
            title: 'Status(IMPORT)',
            Filter: ResourceFilter,
            endpoint: '/api/shop_legacy_order_statuses?pagination=false',
            titleAccessor: 'trans',
          },
        ]}
        reloadFilters={reloadFilters}
        setReloadFilters={setReloadFilters}
      />
    </Paper>
  )
}
